import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const GuestBookPage = () => (
  <Layout>
    <SEO title="Guest Book/ Cartea de oaspeți" />
    <div className="drawer article-content">
      <h1 className="article-title">Guest Book/ Cartea de oaspeți</h1>

      <section>
        <p>
          Cu siguranță ziua nunții voastre este un moment extrem de important
          pentru voi și o sa vreți să fie deosebită și să rămâneți cu multe
          amintiri.
        </p>
        <p>
          Pe lângă pozele făcute de fotograf și cele de la cabina foto
          (photobooth) ai putea apela și la o carte de oaspeți, să ai și câteva
          mesaje, chiar să vă aduceți aminte cum s-au simțit invitații în seara
          respectivă sau să le cereți sfaturi.
        </p>
        <p>
          O sa găsiți o mulțime de variante drăguțe de guest book pe care le
          puteți încadra ulterior și în decorațiunile de acasă.
        </p>
      </section>

      <h3 className="paragh-title">Dacă vreți varianta scrisă/cu mesaje:</h3>
      <section>
        <ul>
          <li>
            tip caiet, unde invitații vor lăsa mesaje sau sfaturi (chiar le
            puteți indica ce vă doriți) și puteți alege un guestbook
            personalizat cu poza voastră sau cu o gravură
          </li>
          <li>
            mesaje scrise pe bilețele. Aici puteți alege o urare pentru voi sau un
            sfat sau chiar să vă lase un mesaj pe care să îl citiți la prima
            aniversare. În cazul în care vreți să fie și amuzant puteți chiar să
            aveți mai multe bilețele, cu mici întrebări sau spații de completat
            și să rugați invitații să răspundă (unde ar trebui să mergeți în
            vacanță, cel mai bun sfat în căsnicie, un nume potrivit pentru
            primul copil etc).
          </li>
        </ul>
      </section>

      <h3 className="paragh-title">Dacă vreți varianta cu poze:</h3>
      <section>
        <ul>
          <li>
            puteți chiar să folositi poze de la photobooth și să îi rugați fie
            să lase un mesaj pe spatele pozei, fie să pună poza în album și să
            scrie un mesaj alături
          </li>
          <li>
            puteți să vă folosiți de un aparat foto instant, îi rugați să facă o
            poză și eventual să scrie și un mic mesaj
          </li>
        </ul>
      </section>

      <h3 className="paragh-title">Alte idei deosebite:</h3>
      <section>
        <ul>
          <li>
            mesaje scrise pe pietre sau pe piese de jenga. Doar că vor fi mesaje
            mai scurte
          </li>
          <li>
            mesaje scrise/semnătura pe un glob pământesc, mai ales dacă aveți o
            tematică potrivită
          </li>
          <li>
            varianta cu amprenta. Achiziționezi o imagine printată pe canvas și
            primești și tușieră cu mai multe culori. Invitații vor lăsa o
            amprentă și vor completa imaginea inițială.
          </li>
          <li>Puzzle și mesaje scrise pe fiecare piesă</li>
          <li>piese din lemn, care formează tot un tablou</li>
          <li>viniluri</li>
          <li>o chitară veche</li>
        </ul>
        <p>
          Cu siguranță veți găsi o variantă potrivită și pentru nunta voastră.
          Pe lângă amintirile rămase, guestbook-ul este și o metodă să implici
          invitații.
        </p>
      </section>

      <section>
        <p>
          În cazul în care ești pe ultima sută de metri și nu mai ai timp să
          comanzi ceva potrivit, chiar ai putea să improvizezi tu un astfel de
          colțișor. Ai nevoie doar de o masă și ceva pe care să scrii
          indicațiile și în varianta mai simplă te poți folosi de un borcan mai
          mare (unde să puna invitații bilețelele), câteva bilețele și pixuri
          colorate/carioci.
        </p>
      </section>

      <div className="nav-section">
        <Link to="/mancarea">◀ Mâncarea de la nuntă</Link>
        <Link to="/honeyMoon">▶ Honey Moon</Link>
      </div>
    </div>
  </Layout>
)

export default GuestBookPage
